import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "../../index.css"
import { FaUser, FaEnvelope } from 'react-icons/fa';
import styles from "./Contact.module.css"
import { toast } from 'sonner';


const Contact = () => {
    const form = useRef();
    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_6qy646y",
                "template_fia04lh",
                form.current,
                "T13zOsKUBC9I670N-"
            )
            .then(
                (result) => {
                    toast.success("Message Sent");
                },
                (error) => {
                    toast.error("Failed to send");
                    console.log(error.text);
                }
            );
        e.target.reset();
    };
    return (
        <div id="contact" className={styles.contact}>
            <div className={styles["contact-wrapper"]}>
                <form ref={form} className="form-section" onSubmit={handleSubmit}>

                    <h1>Contact Us</h1>
                    <div className={styles["input-group"]}>
                        <label htmlFor="name">
                            <FaUser />
                        </label>
                        <input type="text" id="name" name="name" placeholder="Name" required />
                    </div>

                    <div className={styles["input-group"]}>
                        <label htmlFor="name">
                            <FaEnvelope />
                        </label>
                        <input type="email" id="email" name="email" placeholder="Email" required />
                    </div>

                    <textarea id="message" name="message" placeholder="Message" cols="30" rows="3" required></textarea>

                    <button type="submit">Submit</button>
                </form>


                <div className={styles["contact-img"]}>
                    <img src="./contact.svg" alt="Contact" />
                </div>
            </div>
        </div>
    )
}

export default Contact