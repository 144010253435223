import React from 'react'
import styles from "./Footer.module.css"

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className={styles.footer}>
            <div className={styles["footer-section"]}>
                <h4>Company Address</h4>
                <p>No 6 Echendu Close Rumuodomaya, <br /> Rivers State, Nigeria</p>
            </div>
            <div className={styles["footer-section"]}>
                <h4>Contact Information</h4>
                <p>Email: contact@leadsonet.com</p>
                <p>Phone: +2349065436191</p>
            </div>

            <div className={styles["footer-section"]}>
                <p>&copy; 2018 - {currentYear} Lead Sonet Services. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer