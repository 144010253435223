import React from 'react';
import '../../index.css';
import styles from './Intro.module.css';

const Intro = () => {
    return (
        <section id="intro" className={styles.intro}>
            <div className={styles.bannerSection}>
                <div className={styles.introContent} data-aos="zoom-in">
                    <h1>Welcome to  <br /><span>Lead Sonet Services</span></h1>
                    <p>Explore Innovation with Us! 🚀 Offering Computer Hardware Sales & Repair, Software Development, Mobile App Development, Tech Consulting, Organizing Seminars, Conferences, And Symposiums, and Real Estate Services.</p>
                </div>
            </div>

        </section>
    );
};

export default Intro;
