import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [toggleBtn, setToggleBtn] = useState(false);
    const [activeSection, setActiveSection] = useState('intro');

    const handleToggle = () => {
        setToggleBtn((prevToggle) => !prevToggle);
    };

    const handleNavLinkClick = (section) => {
        document.getElementById(section).scrollIntoView();
        setTimeout(() => {
            setActiveSection(section);
            if (toggleBtn) {
                setToggleBtn(false);
            }
        }, 100);
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['intro', 'about', 'services', 'contact'];

            for (const section of sections) {
                const sectionElement = document.getElementById(section);
                const rect = sectionElement.getBoundingClientRect();

                if (
                    rect.top <= window.innerHeight / 2 &&
                    rect.bottom >= window.innerHeight / 2
                ) {
                    setActiveSection(section);
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav>
            <div className="logo">
                <Link to="/" onClick={() => handleNavLinkClick('intro')}>
                    <img src="./lead-logo.png" alt="logo" />
                </Link>
            </div>
            <div className="hamburger" onClick={handleToggle}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            <ul className={`nav-links ${toggleBtn ? 'open' : ''}`}>
                <li>
                    <Link to="/" onClick={() => handleNavLinkClick('intro')} className={activeSection === 'intro' ? 'active' : ''}>
                        Home
                    </Link>
                </li>
                <li>
                    <Link to="/" onClick={() => handleNavLinkClick('about')} className={activeSection === 'about' ? 'active' : ''}>
                        About
                    </Link>
                </li>
                <li>
                    <Link to="/" onClick={() => handleNavLinkClick('services')} className={activeSection === 'services' ? 'active' : ''}>
                        Services
                    </Link>
                </li>
                <li>
                    <Link to="/" onClick={() => handleNavLinkClick('contact')} className={activeSection === 'contact' ? 'active' : ''}>
                        Contact
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
