import React, { useEffect, useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import styles from "./Slider.module.css"

const Slider = () => {
    const slides = [
        {
            url: './11.jpg',
            title: "Computer Hardware Sales and Repair",
            content: `Whether you're in need of cutting-edge computer hardware or require expert repair services, we've got you covered. Our skilled technicians ensure that your hardware functions optimally, keeping your business operations seamless`,
        },
        {
            url: './22.jpg',
            title: "Software Development",
            content: "Harness the power of bespoke software solutions tailored to your unique requirements. Our expert development team crafts innovative software that enhances efficiency, productivity, and overall business performance.",
        },
        {
            url: './3.jpg',
            title: "Mobile Application Development",
            content: "Stay ahead in the mobile era with our custom mobile application development services. From concept to deployment, we create user-friendly and feature-rich apps that elevate your brand in the digital landscape.",
        },

        {
            url: './4.jpg',
            title: "Technology Consulting",
            content: "Navigating the ever-changing tech landscape can be challenging. Our seasoned consultants provide strategic guidance to help you make informed decisions, aligning your technology investments with your business objectives.",
        },
        {
            url: './55.jpg',
            title: "Seminars, Conferences, and Symposiums",
            content: "Knowledge-sharing is at the core of our values. We organize engaging seminars, conferences, and symposiums, fostering a collaborative environment for corporate and private organizations to stay abreast of the latest industry trends and insights.",
        },
        {
            url: './6.jpg',
            title: "Real Estate Services",
            content: "As trusted real estate agents, we understand the importance of finding the perfect space for your business. Whether you're looking to buy, sell, or lease, our real estate experts are dedicated to helping you make informed decisions that align with your goals.",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [currentIndex]);

    return (
        <div className={`max-width-container ${styles["carousel-container"]} group`}>
            <div className={styles["text-overlay"]}>
                <h1>{slides[currentIndex].title}</h1>
                {/* <p>{slides[currentIndex].content}</p> */}
            </div>
            <div
                style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
                className={styles["carousel-image"]}
            ></div>
            <div className={`${styles["left-arrow"]}`} onClick={prevSlide}>
                <BsChevronCompactLeft size={30} />
            </div>
            <div className={`${styles["right-arrow"]}`} onClick={nextSlide}>
                <BsChevronCompactRight size={34} />
            </div>
            <div className={styles["carousel-dots"]}>
                {slides.map((slide, slideIndex) => (
                    <div
                        key={slideIndex}
                        onClick={() => goToSlide(slideIndex)}
                        className={styles.dot}
                    >
                        <RxDotFilled size={26} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Slider;