import { FaMobile, FaLaptopCode, FaUserFriends } from 'react-icons/fa';
import { FaComputer } from 'react-icons/fa6';
import { TbDeviceMobileCode } from "react-icons/tb";
import { MdOutlineRealEstateAgent } from "react-icons/md";
const servicesData = [
    {
        id: 1,
        icon: <FaComputer size={45} />,
        title: "Computer Hardware Sales and Repair",
        content: `Whether you're in need of cutting-edge computer hardware or require expert repair services, we've got you covered. Our skilled technicians ensure that your hardware functions optimally, keeping your business operations seamless`,
    },
    {
        id: 2,
        icon: <FaLaptopCode size={45} />,
        title: "Software Development",
        content: "Harness the power of bespoke software solutions tailored to your unique requirements. Our expert development team crafts innovative software that enhances efficiency, productivity, and overall business performance.",
    },
    {
        id: 3,
        icon: <TbDeviceMobileCode size={45} />,
        title: "Mobile Application Development",
        content: "Stay ahead in the mobile era with our custom mobile application development services. From concept to deployment, we create user-friendly and feature-rich apps that elevate your brand in the digital landscape.",
    },
    {
        id: 4,
        icon: <FaMobile size={45} />,
        title: "Technology Consulting",
        content: "Navigating the ever-changing tech landscape can be challenging. Our seasoned consultants provide strategic guidance to help you make informed decisions, aligning your technology investments with your business objectives.",
    },
    {
        id: 5,
        icon: <FaUserFriends size={45} />,
        title: "Seminars, Conferences, and Symposiums",
        content: "Knowledge-sharing is at the core of our values. We organize engaging seminars, conferences, and symposiums, fostering a collaborative environment for corporate and private organizations to stay abreast of the latest industry trends and insights.",
    },
    {
        id: 6,
        icon: <MdOutlineRealEstateAgent size={45} />,
        title: "Real Estate Services",
        content: "As trusted real estate agents, we understand the importance of finding the perfect space for your business. Whether you're looking to buy, sell, or lease, our real estate experts are dedicated to helping you make informed decisions that align with your goals.",
    },
];

export default servicesData;
