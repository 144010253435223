import React from "react"
import Intro from "../introSection/Intro"
import About from "../about/About"
import Services from "../services/Services"
import Contact from "../contact/Contact"
import Footer from "../footer/Footer"
import styles from "./Home.module.css"
import Slider from "../slider/Slider"

const Home = () => {
    return (
        <div className={styles.home}>
            <div>
                <Intro />
                <About />
                <Services />
                <Slider />
                <Contact />
                <Footer />
            </div>
        </div>
    )
}

export default Home