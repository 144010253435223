import React from 'react'
import "../../index.css"
import styles from "./Services.module.css"
import servicesData from '../../data';
import Card from '../card/Card';

const Services = () => {
    return (
        <div id="services" className={styles.services}>
            <div className={styles.h1}>
                <h1 className={styles['styled-heading']}>
                    Our Services
                </h1>
            </div>
            <div className={styles["services-wrapper"]}>
                {servicesData.map((service) => (
                    <Card key={service.id}>
                        <div className={styles.serviceItem} data-aos="flip-up">
                            <div className={styles.serviceIcon}>{service.icon}</div>
                            <h3>{service.title}</h3>
                            <p>{service.content}</p>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    )
}

export default Services