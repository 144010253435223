import React from 'react';
import '../../index.css';
import styles from './About.module.css';

const About = () => {
    return (
        <section id='about' className={styles['about-wrapper']}>
            <h1 className={styles['styled-heading']}>
                About Us
            </h1>
            <div className={styles.about} data-aos="zoom-in-up">
                <div className={styles.aboutImage}>
                    <img src='./lead-shirt.png' alt='About Me' />
                </div>

                <div className={styles.aboutDetails} >
                    <p>
                        We specialize in seamless technology solutions. From cutting-edge computer hardware sales
                        and expert repairs to custom software and mobile application development,
                        we cater to your unique technology needs. Our seasoned technology consultants provide strategic guidance,
                        ensuring your investments align with your business goals. As trusted real estate agents,
                        we also help you find the ideal physical space for your endeavors.
                        Additionally, we organize insightful seminars, conferences, and symposiums, fostering collaboration and
                        knowledge-sharing for both corporate and private organizations. Explore innovation with us at Lead Sonet Services.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;
